import React, { useEffect, useState } from "react"
import ReactGA from "react-ga"
import { Link } from "@reach/router"
import { Cookies } from "react-cookie-consent"

const COOKIE_EXPIRE_TIME = 150

export const CookieConsentDistionary = {
  "pl-PL": {
    infoLabel:
      "Ta strona korzysta z plików cookie w celu zwiększenia komfortu użytkowania.",
    buttonText: "Zapisz ustawienia",
    checkButtonText: "Sprawdź",
    hideDetailsButtonText: "Ukryj",
    declineButtonText: "Odrzucam",
    privacyPolicyRedirectPrefix: "Zobacz naszą",
    privacyPolicyRedirectSuffix: "by dowiedzieć się więcej.",
    privacyPolicyLink: (
      <Link className="privacyPolicyLink" to={"/page/privacy-policy"}>
        Politykę prywatności
      </Link>
    ),
  },
  "en-GB": {
    infoLabel: "This website uses cookies to enhance the user experience.",
    buttonText: "Save settings",
    hideDetailsButtonText: "Hide",
    checkButtonText: "Check details",
    declineButtonText: "I decline",
    privacyPolicyRedirectPrefix: "See our",
    privacyPolicyRedirectSuffix: "for more.",
    privacyPolicyLink: (
      <Link className="privacyPolicyLink" to={"/page/privacy-policy"}>
        Privacy Policy
      </Link>
    ),
  },
}

const useCookieConsent = language => {
  const [cookieConsentProps, setCookieConsentProps] = useState({
    activeCookies: {
      essentialCookies: true,
      analyticalCookies: false,
      functionalCookies: false,
      advertisingCookies: false,
    },
    buttonText: CookieConsentDistionary[language].buttonText,
    expires: COOKIE_EXPIRE_TIME,
    expanded: false,
    checkButtonText: CookieConsentDistionary[language].checkButtonText,
    cookieName: "cookieConsent",
    infoLabel: CookieConsentDistionary[language].infoLabel,
    hideDetailsButtonText:
      CookieConsentDistionary[language].hideDetailsButtonText,
    privacyPolicyRedirect: [
      CookieConsentDistionary[language].privacyPolicyRedirectPrefix,
      CookieConsentDistionary[language].privacyPolicyRedirectSuffix,
    ],
    privacyPolicyLink: CookieConsentDistionary[language].privacyPolicyLink,
    theme: {
      buttonStyle: {
        margin: 0,
        color: "#f2f6f6",
        borderRadius: "25px",
        padding: "5px 15px",
        border: "1px solid #c4c4c442",
        backgroundColor: "#bd8f34",
        fontFamily: "F37 Wicklow VF",
      },
      declineButtonStyle: {
        margin: "0 15px 0 0",
        color: "#ffffff3d",
        padding: "5px 15px",
        borderRadius: "25px",
        border: "1px solid #ffffff3d",
        backgroundColor: "transparent",
        fontFamily: "F37 Wicklow VF",
      },
      banner: {},
    },
  })

  const handleToggleExpand = () => {
    if (cookieConsentProps.expanded) {
      setCookieConsentProps({
        ...cookieConsentProps,
        expanded: false,
      })
    } else {
      setCookieConsentProps({
        ...cookieConsentProps,
        expanded: true,
      })
    }
  }

  const selectCookieOption = (event, cookieName) => {
    const isAnalyticalCookies = cookieName === "analyticalCookies"

    if (event.target.checked) {
      Cookies.set(cookieName, event.target.checked)
      isAnalyticalCookies && initialiseGaCookies()
    } else {
      Cookies.remove(cookieName)
      isAnalyticalCookies && removeGaCookies()
    }

    setCookieConsentProps({
      ...cookieConsentProps,
      activeCookies: {
        ...cookieConsentProps.activeCookies,
        [cookieName]: event.target.checked,
      },
    })
  }

  const handleAcceptCookie = () => {}

  const initialiseGaCookies = () => {
    if (process.env.GATSBY_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.GATSBY_GA_TRACKING_ID)
    }
  }

  const removeGaCookies = () => {
    Cookies.remove("_ga")
    Cookies.remove("_gat")
    Cookies.remove("_gid")
  }

  const handleDeclineCookie = () => {
    removeGaCookies()
  }

  const getCookieValue = cookieName => {
    const cookieValue = Cookies.get(cookieName)
    return cookieValue === undefined || !JSON.parse(cookieValue) ? false : true
  }

  useEffect(() => {
    const activeCookies = {}

    Object.keys(cookieConsentProps.activeCookies).forEach(key => {
      if (key === "essentialCookies") {
        activeCookies[key] = true
      } else {
        activeCookies[key] = getCookieValue(key)
      }
      Cookies.set(key, activeCookies[key])
    })

    setCookieConsentProps({
      ...cookieConsentProps,
      activeCookies: {
        ...activeCookies,
        essentialCookies: true,
      },
    })
  }, [])

  useEffect(() => {
    setCookieConsentProps({
      ...cookieConsentProps,
      infoLabel: CookieConsentDistionary[language].infoLabel,
      buttonText: CookieConsentDistionary[language].buttonText,
      declineButtonText: CookieConsentDistionary[language].declineButtonText,
      checkButtonText: CookieConsentDistionary[language].checkButtonText,
      hideDetailsButtonText:
        CookieConsentDistionary[language].hideDetailsButtonText,
      privacyPolicyRedirect: [
        CookieConsentDistionary[language].privacyPolicyRedirectPrefix,
        CookieConsentDistionary[language].privacyPolicyRedirectSuffix,
      ],
      privacyPolicyLink: CookieConsentDistionary[language].privacyPolicyLink,
    })
  }, [language])

  return [
    cookieConsentProps,
    handleAcceptCookie,
    handleDeclineCookie,
    handleToggleExpand,
    selectCookieOption,
  ]
}

export default useCookieConsent
