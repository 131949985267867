import { Router, useLocation } from "@reach/router"
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"
import ReactGA from "react-ga"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useTranslation } from "react-i18next"
import Article from "../components/shared/article/article"
import useCookieConsent from "../hooks/useCookieConsent"
import HomePage from "../modules/HomePage"
import { useFetch } from "../hooks/useFetch"
import { ENDPOINTS } from "../api/endpoints"
import { componentDictionary, routePrefix } from "../const/routes"
import CookieForm from "../components/form/cookie-form"
import classNames from "classnames"

const App = () => {
  const [navList, setNavList] = React.useState(null)
  const location = useLocation()
  const { i18n } = useTranslation()
  const [
    cookieConsentProps,
    handleDeclineCookie,
    handleAcceptCookie,
    handleToggleExpand,
  ] = useCookieConsent(i18n.language)
  const { response } = useFetch(ENDPOINTS.HEADER, i18n.language)

  React.useEffect(() => {
    const isConsent = getCookieConsentValue()
    if (isConsent === "true") {
      handleAcceptCookie()
    }
  }, [])

  React.useEffect(() => {
    if (cookieConsentProps.expanded) {
      window.document
        .querySelectorAll("div.navbar-container")
        .forEach(element => {
          element.style.display = "none"
        })
    } else {
      window.document
        .querySelectorAll("div.navbar-container")
        .forEach(element => {
          element.style.display = "block"
        })
    }
  }, [cookieConsentProps.expanded])

  React.useEffect(() => {
    if (response?.data?.attributes?.navigation?.length) {
      const navData = response?.data?.attributes?.navigation

      const list = navData.map(navItem => ({
        path: routePrefix + navItem.path.split("/")[2],
        component: componentDictionary[navItem.component],
      }))

      setNavList(list)
    }
  }, [response])

  React.useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return (
    <Layout>
      <Seo title="Green Lanes" />
      <Router>
        {navList?.length
          ? navList.map(navItem =>
              navItem?.component ? (
                <navItem.component path={navItem.path} key={navItem.path} />
              ) : null
            )
          : null}

        <HomePage path={routePrefix} />
        <Article path={`${routePrefix}news/art/:slug`} />
      </Router>

      <CookieConsent
        enableDeclineButton
        location="bottom"
        declineButtonStyle={cookieConsentProps.theme.declineButtonStyle}
        buttonText={cookieConsentProps.buttonText}
        declineButtonText={cookieConsentProps.declineButtonText}
        cookieName={cookieConsentProps.cookieName}
        style={{
          ...cookieConsentProps.theme.banner,
          height: cookieConsentProps.height,
        }}
        buttonStyle={cookieConsentProps.theme.buttonStyle}
        expires={cookieConsentProps.expires}
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        containerClasses={classNames({
          CookieConsent: true,
          expanded: cookieConsentProps.expanded,
        })}
        buttonWrapperClasses="buttons"
      >
        <div
          className={classNames({
            open: cookieConsentProps.expanded,
            cookieInfoWrapper: true,
          })}
        >
          <div className="cookies-text-wrapper">
            <p style={{ margin: 0 }}>{cookieConsentProps.infoLabel}</p>
            {cookieConsentProps.expanded ? null : (
              <p style={{ margin: 0 }}>
                {cookieConsentProps.privacyPolicyRedirect[0]}
                {cookieConsentProps.privacyPolicyLink}
                {cookieConsentProps.privacyPolicyRedirect[1]}
              </p>
            )}
          </div>
          {cookieConsentProps.expanded ? (
            <div className="cookie-form-wrapper">
              <CookieForm />
            </div>
          ) : null}
          <div>
            <button
              className="check-details__button"
              style={cookieConsentProps.theme.buttonStyle}
              onClick={() => handleToggleExpand()}
            >
              {!cookieConsentProps.expanded
                ? cookieConsentProps.checkButtonText
                : cookieConsentProps.hideDetailsButtonText}
            </button>
          </div>
        </div>
      </CookieConsent>
    </Layout>
  )
}

export default App
