import React from "react"
import { useTranslation } from "react-i18next"
import { Cookies } from "react-cookie-consent"
import useCookieConsent from "../../hooks/useCookieConsent"

import "./cookie-form.scss"

const CookieForm = () => {
  const { t, i18n } = useTranslation()
  const [_, __, ___, ____, selectCookieOption] = useCookieConsent(i18n.language)

  const getCookieValue = cookieName => {
    const cookieValue = Cookies.get(cookieName)

    if (!cookieValue || !JSON.parse(cookieValue)) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <div className="cookie-form">
        <div className="cookies-info-wrapper">
          <h4 className="cookies-info-heading">
            {t("cookies.privacy_settings_title")}
          </h4>
          <p className="cookies-info-description">
            {t("cookies.privacy_settings_description")}
          </p>
        </div>

        <div className="cookies-info-details-wrapper">
          <h4 className="cookies-info-details-heading">
            {t("cookies.managing_consent_preferences_title")}
          </h4>

          <div className="cookies-types-wrapper">
            {/* ESSENTIAL COOKIES */}
            <div className="cookies-types-header">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="essentialCookiesInput"
                disabled
                checked={true}
                onChange={event =>
                  selectCookieOption(event, "essentialCookies")
                }
              ></input>
              <label
                className="form-check-label"
                htmlFor="essentialCookiesInput"
              >
                {t("cookies.cookies_type.essential_cookies_title")}
              </label>

              <p className="cookies-info-description">
                {t("cookies.cookies_type.essential_cookies_description")}
              </p>
            </div>
            {/* ANALYTICAL COOKIES */}
            <div className="cookies-types-header">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="analyticalCookiesInput"
                checked={getCookieValue("analyticalCookies")}
                onChange={event =>
                  selectCookieOption(event, "analyticalCookies")
                }
              ></input>
              <label
                className="form-check-label"
                htmlFor="analyticalCookiesInput"
              >
                {t("cookies.cookies_type.analytical_cookies_title")}
              </label>

              <p className="cookies-info-description">
                {t("cookies.cookies_type.analytical_cookies_description")}
              </p>
            </div>
            {/* FUNCTIONAL COOKIES */}
            <div className="cookies-types-header">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="functionalCookiesInput"
                checked={getCookieValue("functionalCookies")}
                onChange={event =>
                  selectCookieOption(event, "functionalCookies")
                }
              ></input>
              <label
                className="form-check-label"
                htmlFor="functionalCookiesInput"
              >
                {t("cookies.cookies_type.functional_cookies_title")}
              </label>
              <p className="cookies-info-description">
                {t("cookies.cookies_type.functional_cookies_description")}
              </p>
            </div>
            {/* ADVERTISING COOKIES */}
            <div className="cookies-types-header">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="advertisingCookiesInput"
                checked={getCookieValue("advertisingCookies")}
                onChange={event =>
                  selectCookieOption(event, "advertisingCookies")
                }
              ></input>
              <label
                className="form-check-label"
                htmlFor="advertisingCookiesInput"
              >
                {t("cookies.cookies_type.advertising_cookies_title")}
              </label>

              <p className="cookies-info-description">
                {t("cookies.cookies_type.advertising_cookies_description")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CookieForm
